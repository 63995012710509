<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab
        :to="{ name: 'Import Files (original)' }"
      >
      Import Files (original)
      </v-tab>
      <v-tab
        :to="{ name: 'Import Files (beta)' }"
      >
      Import Files (beta)
      </v-tab>
      <v-tab
        :to="{ name: 'Import Vouchers from Depts' }"
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
          Import Vouchers from Depts
        </span>
        </template>
        <span>(Depreciated) - Please use "Import File (Beta)"</span>
      </v-tooltip>
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'ImportFiles',
  components: {
  },
};
</script>
